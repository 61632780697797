.loading {
  position: absolute;
  left: calc(50% - 3rem);
  top: calc(50% - 3rem);
}

.loading::after {
  content: '';
  border: 1rem solid rgba(0, 0, 0, 0.2);
  border-left: 1rem solid #000000;
  border-radius: 50%;
  display: block;
  height: 6rem;
  width: 6rem;
  animation: loadader 1.1s infinite linear;
}

@keyframes loadader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.console {
  background-color: black;
  color: greenyellow;
  height: 100vh;
}

.console::after { 
  border: greenyellow 6px solid;
  border-bottom-width: 2px;
  border-top-width: 2px;
  content: '';
  cursor: progress;
  height: 80%;
  margin-left: 5px;
  animation: blink 1s infinite linear;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
