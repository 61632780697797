$body-bg: #F9F9F9;
$body-color: #333;
$blue: #5563AA;
$orange: #ff6212;
$text-muted: #d5dbe0;

.btn {
  background-color: transparent;
}

@import '~bootstrap/scss/bootstrap.scss';

.custom-file-input {
  cursor: pointer;
}

.card + .card {
  margin-top: 1rem;
}

h1 {
  border-left: 0.2rem solid var(--cyan);
  padding-left: 1rem;
}
h2 {
  border-left: 0.2rem solid var(--green);
  padding-left: 1rem;
}

[tooltip] {
  position: relative;
}

[tooltip]:after {
  color: var(--white);
  content: attr(tooltip);
  background-color: var(--gray-dark);
  border-radius: 4px;
  bottom: auto;
  font-size: 0.75rem;
  opacity: 0;
  padding: 4px 8px;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: left;
  top: 100%;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition-delay: 0.2s;
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  white-space: nowrap;
  z-index: 1;
}

[tooltip]:hover:after {
  opacity: 1;
  transform: translateY(2px);
  visibility: visible;
}
[tooltip]:focus {
  outline:0;
}

@supports(padding: max(0px)) {
  .container-fluid {
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: m#{a}x(env(safe-area-inset-left), 15px);
    padding-right: m#{a}x(env(safe-area-inset-right), 15px);
  }
}
