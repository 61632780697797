@media (max-width: 991px) {
  .navbar-collapse {
    left: 0;
    opacity: 0;
    padding: 0 6px;
    position: absolute;
    right: 0;
    top: 100%;
    transition: all 0.3s ease;
    visibility: hidden;
    z-index: 1;
  }

  .navbar-collapse--show {
    opacity: 1;
    visibility: visible;
  }
}

@supports (padding: max(0px)) {
  .navbar {
    padding-left: max(env(safe-area-inset-left), 1rem);
    padding-right: max(1rem, env(safe-area-inset-right));
  }

  .navbar-collapse {
    padding-left: max(env(safe-area-inset-left), 1rem);
  }
}
